import React from 'react'
import Fade from 'react-reveal/Fade'

const Five = props => (
  <section id="five" className="wrapper style2 special fade inactive">
    <Fade duration={2500}>
      <div className="container">
        <header>
          <h2>Contact Us</h2>
        </header>
        <form id='Contact' name="Contact Form" method="POST" data-netlify="true">
          <div className="row gtr-uniform gtr-50" align="center">
            <input type="hidden" name="form-name" value="Contact Form" />
          <div className="col-12">
            <input type="email" name="email" placeholder="Your Email Address" />
          <button className="button primary fit" type="submit">Submit</button>
            </div>
          </div>
        </form>
      </div>
    </Fade>
  </section>
)
export default Five
