import React from 'react'
import pic03 from '../assets/images/pic03.jpg'
import { Link as ScrollLink } from 'react-scroll'
import Fade from 'react-reveal/Fade'

const Two = props => (
  <section
    id="two"
    className="spotlight style2 right inactive"
    style={{ backgroundImage: `url(${pic03})` }}
  >
    <span className="image fit main">
      <img src={pic03} alt="" />
    </span>
    <Fade right big>
      <div className="content">
        <header>
          <h2>We know Analytics and Social Media channels</h2>
        </header>
        <p>
        To help brands engage consumers at scale we developled our proprietary content distribution channels that can reach every American consumer segment.
        Our highly diversified digital campaigns engage targets at volume while providing real-time performance results via our internal multivariate, 
        closed-loop conversion processes.

        </p>
    
      </div>
    </Fade>
    <ScrollLink
      to="three"
      className="goto-next"
      activeClass="active"
      smooth={true}
      offset={50}
      duration={1500}
      spy={true}
    >
      Next
    </ScrollLink>
  </section>
)

export default Two
